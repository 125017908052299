<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content main_content">
      <div class="bg"></div>
      <div style="position: relative; zIndex: 1;">
        <h1 class="h1">
          {{data.laboratory_name}}
          <span class="specimen">{{data.specimen}}</span>
        </h1>
        <div class="title">检验简介</div>
        <div v-if="data.introduce && !loading" :class="['content-line',{'show_more_content': more_one}]">
           <div class="content" ref="one" v-html="hander_content(data.introduce)"></div>
           <div class="line" v-if="height_one>0 && height_one>=default_height && !more_one"/>
        </div>
        <div class="no-data" v-if="!data.introduce && !loading">
          <img  width="150" src="../../assets/img/no-data.png"/>
            暂无数据
        </div>
        <div class="more" @click="show_more_one" v-if="height_one>0 && height_one>=default_height && !more_one">点击展开全文<img width="13" height="13" src="../../assets/img/down-arrow.png"/></div>
        <div class="title">临床意义</div>
        <div v-if="data.clinical" :class="['content-line',{'show_more_content': more_two}]" >
          <div class="content" ref="two" v-html="hander_content(data.clinical)"></div>
          <div class="line" v-if="height_two>0 && height_two>=default_height && !more_two"/>
        </div>
        <div class="no-data" v-if="!data.clinical && !loading">
          <img  width="150" src="../../assets/img/no-data.png"/>
            暂无数据
        </div>
        <div class="more" @click="show_more_two" v-if="height_two>0 && height_two>=default_height && !more_two">点击展开全文<img width="13" height="13" src="../../assets/img/down-arrow.png"/></div>
      </div>
    </div>
  </div>
</template>
<script>
import { laboratory_detail } from './service'

export default {
  data () {
    return {
      data: {},
      more_one: false,
      more_two: false,
      height_one: 0,
      height_two: 0,
      default_height: 200,
      loading: false
    }
  },
  computed: {

  },
  components: {
  },
  watch: {
  },
  created () {
    this.init_data()
  },
  methods: {
    hander_content(val) {
      let text = '&emsp;&emsp;' + val
      text = text.replace(/\n/g,'<br>&emsp;&emsp;')
      return text
    },
    show_more_one() {
      this.more_one = true
    },
    show_more_two() {
      this.more_two = true
    },
    async init_data () {
      this.loading = true
      try {
        const {data} = await laboratory_detail({
          name: this.$route.query.name,
          specimen: this.$route.query.specimen,
        })
        this.data = data
        this.$nextTick(()=>{
          this.height_one = this.$refs.one ? this.$refs.one.offsetHeight : 0
          this.height_two = this.$refs.two ? this.$refs.two.offsetHeight : 0
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.main_content {
  position: relative;
  padding-bottom: 30px;
  & .bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 160px;
    height: 200px;
    background: url("../../assets/img/arc.png") left bottom no-repeat;
    background-size: 320px;
    z-index: 0;
  }
  & .h1 {
    font-size: 24px;
    line-height: 34px;
    color: #242938;
    margin-top: 30px;
    padding: 0px 16px;
    & .specimen {
      font-size: 16px;
      color: #999;
    }
  }
  & .title {
    font-size: 16px;
    color: #242938;
    margin-top: 35px;
    position: relative;
    margin: 35px 32px 0px;
    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: -14px;
      height: 16px;
      width: 6px;
      border-radius: 6px;
      background: #0088FF;
    }
  }
  & .content-line {
      position: relative;
      max-height: 200px;
      overflow : hidden;
      &.show_more_content {
        max-height: inherit;
      }
     & .content {
      font-size: 14px;
      line-height: 24px;
      color: #565656;
      padding: 0 32px;
      margin-top: 15px;

    }
    & .line {
      right: 32px;
      left:32px;
      height: 25px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      position: absolute;
      bottom: 0;
    }
  }
}
.more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:18px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #1890FF;
    & >img {
      margin-left: 6px;
    }
  }
.no-data {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #565656;
  & img {
    margin-bottom: 10px;
  }
}
</style>
